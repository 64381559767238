import React, { useEffect, useState } from 'react';
import styles from './action-bar.module.scss';
import {
  CallToAction,
  Field,
  Flex,
  getDataIcons,
  Heading,
  Img,
  Popover,
  Spinner,
  Text,
  Tooltip,
  truncateText,
  useFeedback,
} from '@nex/labs';
import { isBlockInView } from '@/utils';
import { useArtboardStore } from '@/state/useStore';
import { ACTION_CONSTANTS } from '../../utils/constants';
import { motion } from 'framer-motion';
import RatioIcon from '@nex/icons/svg/blocks/ratio.svg';
import ImageIcon from '@nex/icons/svg/dashboard/image.svg';

import PromptIcon from '@nex/icons/svg/blocks/prompt.svg';

import DeleteIcon from '@nex/icons/svg/misc/trash.svg';
import CropIcon from '@nex/icons/svg/misc/crop.svg';
import AssetIcon from '@nex/icons/svg/blocks/assets.svg';
import SketchControlIcon from '@nex/icons/svg/blocks/sketch.svg';

import { WeightSlider } from '../misc/weight-slider';
import classNames from 'classnames';
import { AspectSettings } from '../../views/prompt';
import { useArtboardGenerate } from '../../hooks/useArtboardGenerate';
import { CompositionStatus } from '@nex/types/sketch';
import { IconMap } from '@/components/misc/controls-icon';
import { useFileUpload } from '@/components/upload/useUpload';

export const ActionBar = () => {
  const {
    setBlock,
    setActiveTab,
    defaultConfig,
    models,
    setConfig,
    requests,
    isGenerating,
  } = useArtboardStore();

  const KEY = 'prompt';

  const normalizeGenerate = useArtboardGenerate();
  const isInView = (key: string) => {
    return isBlockInView('assets').find((item) => item.data?.subMeta === key);
  };

  const presetName = isBlockInView('preset')[0]?.data?.name;

  const numberOfRequests = requests?.filter(
    (rq: any) => rq.status === CompositionStatus.PROCESSING
  ).length;
  return (
    <div className={styles.ActionBarWrapper}>
      {requests?.length > 0 && (
        <div
          className={classNames([
            styles.ActionRequests,
            'flex flex-col gap-[6px] w-full flex-1 mb-2',
          ])}
        >
          <Flex gap={8} alignItems="center" justifyContent="center">
            <Spinner
              size={16}
              spinner="logo"
              style={{
                margin: '0',
              }}
            />{' '}
            <Text className="opacity-70">
              {numberOfRequests} request(s) processing{' '}
            </Text>
          </Flex>
        </div>
      )}
      <div className={styles.ActionInput} id="action-input">
        <Field.Textarea
          rows={2}
          value={isBlockInView(KEY)[0]?.data || ''}
          onChange={(e: any) => setBlock(KEY, e.target.value)}
          placeholder="Add your prompt"
        ></Field.Textarea>
      </div>

      <Flex.Row className={styles.ActionBlocksSettings}>
        {defaultConfig.model?.blocks?.length === 0 && (
          <Flex.Column className={styles.ActionBlocksSettings__empty}>
            <Text weight={600} fontSize="calc(var(--font-p) + 1px)">
              Image controls are not available for Flux
            </Text>
            <CallToAction.button
              size="sm"
              variant="secondary"
              onClick={() => {
                const newModel = models?.find(
                  (model: any) => model.name === 'Ikon-1'
                );

                if (newModel) {
                  setConfig({
                    model: newModel,
                  });
                }
              }}
            >
              Switch to IKON-1
            </CallToAction.button>
          </Flex.Column>
        )}
        {[
          'baseImage',
          'colorImage',
          'poseImage',
          'structureImage',
          'softedgeImage',
        ]?.map((block: any, index: number) => {
          const Icon = IconMap[block as keyof typeof IconMap];
          const currentBlockInfo = ACTION_CONSTANTS.find(
            (item) => item.key === block
          );
          const currentBlocks =
            defaultConfig.model?.blocks || defaultConfig.modelBlocks;

          const hasBlock = currentBlocks?.includes(block);
          return (
            <Popover size="md" key={index} disabled={!hasBlock} trigger="hover">
              <Popover.Trigger>
                <motion.button
                  disabled={!hasBlock}
                  whileHover={{
                    scale: 1.08,
                    rotate: -3,
                    transition: {
                      duration: 0.3,
                      ease: 'easeInOut',
                      type: 'spring',
                      stiffness: 100,
                    },
                  }}
                  key={index}
                  style={{
                    opacity: hasBlock ? 1 : 0.5,
                    filter: hasBlock ? 'none' : 'blur(2px)',
                    ...(isInView(block)
                      ? {
                          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${isInView(block)?.data?.buffer ?? isInView(block)?.data?.src})`,
                        }
                      : {}),
                  }}
                  className={classNames([isInView(block) && styles.active])}
                >
                  <Icon />
                  {currentBlockInfo?.title}
                </motion.button>
              </Popover.Trigger>
              <Popover.Content>
                <div className={styles.ActionBlockSettingsPopover}>
                  <Flex.Row gap={8} alignItems="center">
                    <Icon className={styles.ActionBlockSettingsPopover__icon} />
                    <Heading.h5
                      fontSize="calc(var(--font-h6) + 1px)"
                      weight={700}
                    >
                      {currentBlockInfo?.title}
                    </Heading.h5>
                  </Flex.Row>
                  {isInView(block) ? (
                    <ControlsSettings
                      block={isInView(block)}
                      setBlock={setBlock}
                    />
                  ) : (
                    <EmptyBlockControls currentBlockInfo={currentBlockInfo} />
                  )}
                </div>
              </Popover.Content>
            </Popover>
          );
        })}
      </Flex.Row>

      <Flex.Column gap={8} className={styles.ActionSettingsGenerate}>
        <div className={styles.ActionSettingsMap}>
          <Flex.Row gap={4} alignItems="center">
            <Text weight={600}>
              {defaultConfig.model?.name}{' '}
              {presetName && ` / ${truncateText(presetName, 7)}`}
            </Text>
          </Flex.Row>
          <Flex.Row gap={4} alignItems="center">
            <RatioIcon />
            <Text weight={600}>{defaultConfig.resolution}</Text>
          </Flex.Row>
          <Flex.Row gap={4} alignItems="center">
            <ImageIcon />
            <Text weight={600}>x{defaultConfig.size}</Text>
          </Flex.Row>
          <Popover size="md">
            <Popover.Trigger>
              <button className={styles.ActionSettingsMore}>
                <img src={getDataIcons('settings')} />
              </button>
            </Popover.Trigger>
            <Popover.Content>
              <Flex.Row alignItems="flex-start" className="p-4" gap={18}>
                <Flex.Column gap={12}>
                  <Text weight={600}>Model</Text>
                  <Text>{defaultConfig.model?.name}</Text>
                </Flex.Column>
                {presetName && (
                  <Flex.Column gap={12}>
                    <Text weight={600}>Style</Text>
                    <Text>{presetName}</Text>
                  </Flex.Column>
                )}

                <button
                  className="bg-[var(--primary-gray)] ml-auto p-1 rounded-md h-fit"
                  onClick={() => {
                    setActiveTab('models');
                  }}
                >
                  <img src={getDataIcons('settings')} />
                </button>
              </Flex.Row>
              <Flex.Column gap={12} className="p-4">
                <Flex.Column gap={6}>
                  <Text weight={600}>
                    Number of Generations{' '}
                    <Tooltip content="Click on the number of generations you want">
                      <img
                        src={getDataIcons('help')}
                        className="inline w-[12px] -mt-[2px]"
                      />
                    </Tooltip>
                  </Text>

                  <div className={styles.ButtonPills}>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setConfig({ size: index + 1 })}
                        className={classNames([
                          styles.ButtonPill,
                          index + 1 === defaultConfig.size && styles.active,
                        ])}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                </Flex.Column>
                <AspectSettings />
              </Flex.Column>
            </Popover.Content>
          </Popover>
        </div>
        <CallToAction
          size="sm"
          leadingIcon={<PromptIcon />}
          className="w-full"
          onClick={normalizeGenerate}
          isLoading={isGenerating}
        >
          Generate
        </CallToAction>
      </Flex.Column>
    </div>
  );
};

const ControlsSettings = ({
  block,
  setBlock,
}: {
  block: any;
  setBlock: any;
}) => {
  const { removeBlock, setModal: setCropModal } = useArtboardStore();
  return (
    <>
      <Flex.Column gap={12}>
        <div className={styles.ActionBlockSettingsPopover__image}>
          <Img
            src={block?.data?.buffer ?? block.data.src}
            alt="preset"
            className="rounded-md w-[100%] max-h-[150px] object-cover"
          />
          <Flex.Row
            justifyContent="space-between"
            alignContent="flex-end"
            className={styles.ActionBlockSettingsPopover__image__overlay}
          >
            <span>Assets</span>
            <Flex.Row gap={8}>
              <button
                onClick={() => {
                  setCropModal({
                    type: 'crop',
                    props: {
                      title: `Edit ${block?.data?.subMetaInfo?.title}`,
                      size: 'lg',
                      blockId: block?.data?.subMeta,
                    },
                  });
                }}
              >
                <CropIcon />
              </button>
              <button
                onClick={() => removeBlock('assets', block.data?.subMeta)}
              >
                <DeleteIcon />
              </button>
            </Flex.Row>
          </Flex.Row>
        </div>
        <div className="mb-3">
          <WeightSlider block={block} setBlock={setBlock} />
        </div>
      </Flex.Column>
    </>
  );
};

const EmptyBlockControls = ({
  currentBlockInfo,
}: {
  currentBlockInfo: any;
}) => {
  const { setActiveTab } = useArtboardStore();
  const { onFileUpload } = useFileUpload();

  return (
    <>
      <Text className="opacity-70">{currentBlockInfo?.description}</Text>
      <Text weight={600} className="mt-1">
        Add control from:
      </Text>
      <Flex.Row gap={8} className={styles.ActionBlockSettingsPopover__controls}>
        <CallToAction.input
          variant="clear"
          onFileUpload={onFileUpload}
          multiple
          defaultBehavior={false}
        >
          <AssetIcon className="m-auto" />
          <Text weight={700} align="center" className="mt-[2px]">
            Upload
          </Text>
        </CallToAction.input>
        <button
          onClick={() => {
            setActiveTab('sketch');
          }}
        >
          <SketchControlIcon />
          Collage
        </button>
        <button
          onClick={() => {
            setActiveTab('assets');
          }}
        >
          <AssetIcon />
          Assets
        </button>
      </Flex.Row>
      <Flex.Column gap={10} justifyContent="center">
        <img
          src={currentBlockInfo.image}
          className="w-[100%] max-h-[100px] object-contain rounded-md"
        />
        <Text className="opacity-70" align="center">
          <span className="mr-1">
            <Tooltip
              content="
              This settings are used to control all your generations
            "
            >
              <img src={getDataIcons('help')} className="w-[12px]" />
            </Tooltip>
          </span>
          Learn more
        </Text>
      </Flex.Column>
    </>
  );
};
